import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["county", "region", "location"];

  async fetchRegions() {
    const county = this.countyTarget.value;
    const url = `/county_delivery_groups/${county}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      // Assuming 'region' is a select dropdown
      const regionSelect = this.regionTarget;
      regionSelect.innerHTML = ""; // Clear previous options

      data.forEach((region) => {
        const option = document.createElement("option");
        option.value = region.name; // Replace 'id' with the actual identifier in your data
        option.textContent = region.name; // Replace 'name' with the actual attribute in your data
        regionSelect.appendChild(option);
      });

      // Trigger change event to fetch locations for the newly loaded regions
      regionSelect.dispatchEvent(new Event("change"));
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  }

  async fetchLocations() {
    const county = this.countyTarget.value;
    const region = this.regionTarget.value;
    const url = `/group_delivery_zones/${region}/${county}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      // Assuming 'location' is a select dropdown
      const locationSelect = this.locationTarget;
      locationSelect.innerHTML = ""; // Clear previous options

      data.forEach((location) => {
        const option = document.createElement("option");
        option.value = location.name; // Replace 'id' with the actual identifier in your data
        option.textContent = location.name + (location.label ? ` - ${location.label}` : ''); // Replace 'name' with the actual attribute in your data
        locationSelect.appendChild(option);
      });
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  }

  connect() {
    this.countyTarget.addEventListener("change", () => {
      this.fetchRegions();
    });

    this.regionTarget.addEventListener("change", () => {
      this.fetchLocations();
    });
  }
}
